import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as routesFactory from './locale/routes.factory';
import {
  get404Routes,
  getAnalyticsRoutes,
  getEcommerceSeoRoutes,
  getTradeSeoRoutes
} from "./locale/routes.factory";

const routes: Routes = [
  ...routesFactory.getHomeRoutes(() => import('./pages/solutions-accounting/solutions-accounting.module').then((m) => m.SolutionsAccountingModule)),
  ...routesFactory.getPricingRoutes(() => import('./pages/price-page/price-page.module').then((m) => m.PricePageModule)),
  ...routesFactory.getAccountingRoutes(() => import('./pages/accounting-page/accounting-page.module').then((m) => m.AccountingPageModule)),
  ...routesFactory.getWarehouseRoutes(() => import('./pages/warehouse-page/warehouse-page.module').then((m) => m.WarehousePageModule)),
  ...routesFactory.getRetailRoutes(() => import('./pages/retail-page/retail-page.module').then((m) => m.RetailPageModule)),
  ...routesFactory.getPartnersRoutes(() => import('./pages/partners-page/partners-page.module').then((m) => m.PartnersPageModule)),
  ...routesFactory.getProductsRoutes(() => import('./pages/products-page/products-page.module').then((m) => m.ProductsPageModule)),
  ...routesFactory.getProductsRoutes(() => import('./pages/products-page/products-page.module').then((m) => m.ProductsPageModule)),
  ...routesFactory.getTeamRoutes(() => import('./pages/team-page/team-page.module').then((m) => m.TeamPageModule)),
  ...routesFactory.getServicesRoutes(() => import('./pages/services-page/services-page.module').then((m) => m.ServicesPageModule)),
  ...routesFactory.getTradeRoutes(() => import('./pages/trade-page/trade-page.module').then((m) => m.TradePageModule)),
  ...routesFactory.getEcommerceRoutes(() => import('./pages/ecommerce-page/ecommerce-page.module').then((m) => m.EcommercePageModule)),
  ...routesFactory.getAboutRoutes(() => import('./pages/about-page/about-page.module').then((m) => m.AboutPageModule)),
  ...routesFactory.getCookiePolicyRoutes(() => import('./pages/cookies-policy/cookies-policy.module').then((m) => m.CookiesPolicyModule)),
  ...routesFactory.getLegalRoutes(() => import('./pages/legal-notice/legal-notice.module').then((m) => m.LegalNoticeModule)),
  ...routesFactory.getPrivacyPolicyRoutes(() => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)),
  ...routesFactory.getTermsOfServicesRoutes(() => import('./pages/terms-of-services/terms-of-services.module').then((m) => m.TermsOfServicesModule)),
  // ...routesFactory.getAccountingSoftwareRoutes(() => import('./pages/solutions-accounting/solutions-accounting.module').then((m) => m.SolutionsAccountingModule)),
  ...routesFactory.getInventoryManagementRoutes(() => import('./pages/solutions-trade/solutions-trade.module').then((m) => m.SolutionsTradeModule)),
  ...routesFactory.getEcommerceSoftwareRoutes(() => import('./pages/solutions-ecommerce/solutions-ecommerce.module').then((m) => m.SolutionsEcommerceModule)),
  ...routesFactory.getPointOfSaleRoutes(() => import('./pages/solutions-retail/solutions-retail.module').then((m) => m.SolutionsRetailModule)),
  ...routesFactory.getBlogRoutes(() => import('./pages/blog-list/blog-list.module').then((m) => m.BlogListModule)),
  ...routesFactory.getBlogPageRoutes(() => import('./pages/blog-page/blog-page.module').then((m) => m.BlogPageModule)),
  ...routesFactory.getLexiconRoutes(() => import('./pages/lexicon-list/lexicon-list.module').then((m) => m.LexiconListModule)),
  ...routesFactory.getLexiconPageRoutes(() => import('./pages/lexicon-page/lexicon-page.module').then((m) => m.LexiconPageModule)),
  ...routesFactory.getAuthorRoutes(() => import('./pages/author-page/author-page.module').then((m) => m.AuthorPageModule)),
  ...routesFactory.getSignUpRoutes(() => import('./pages/sign-up/sign-up.module').then((m) => m.SignUpModule)),
  ...routesFactory.getAnalyticsRoutes(() => import('./pages/analytics-page/analytics-page.module').then((m) => m.AnalyticsPageModule)),
  ...routesFactory.getCreateUserRoutes(() => import('./pages/create-user-page/create-user-page.module').then((m) => m.CreateUserPageModule)),
  ...routesFactory.getWorkspaceCreatedRoutes(() => import('./pages/created-workspace/created-workspace.module').then((m) => m.CreatedWorkspaceModule)),
  ...routesFactory.getSaasRoutes(() => import('./pages/saas-page/saas-page.module').then((m) => m.SaasPageModule)),

  ...routesFactory.getTradeSeoRoutes(() => import('./pages/solutions-trade-seo-page/trade-seo-page.module').then((m) => m.TradeSeoPageModule)),
  ...routesFactory.getEcommerceSeoRoutes(() => import('./pages/solutions-ecommerce-seo-page/ecommerce-seo-page.module').then((m) => m.EcommerceSeoPageModule)),
  ...routesFactory.getAccountingSeoRoutes(() => import('./pages/solutions-accounting-seo-page/accounting-seo-page.module').then((m) => m.AccountingSeoPageModule)),
  ...routesFactory.getErpSoftwareRoutes(() => import('./pages/erp-software-page/erp-software-page.module').then((m) => m.ErpSoftwarePageModule)),
  ...routesFactory.getRunpleFreeSeoRoutes(() => import('./pages/runple-free-seo-page/runple-free-seo-page.module').then((m) => m.RunpleFreeSeoPageModule)),
  ...routesFactory.getOnlineStoreSeoRoutes(() => import('./pages/online-store-seo-page/online-store-seo-page.module').then((m) => m.OnlineStoreSeoPageModule)),
  ...routesFactory.getReleaseNotesRoutes(() => import('./pages/release-notes-page/release-notes-page.module').then((m) => m.ReleaseNotesPageModule)),

  ...routesFactory.get404Routes(() => import('./pages/not-page/not-page.module').then((m) => m.NotPageModule)),

  ...routesFactory.getTradeOfferRoutes(
    () => import('./pages/external-pages/offer-external-page/offer-external-page.module').then((m) => m.OfferExternalPageModule)
  ),

  ...routesFactory.getSignInRoutes(
    () => import('./pages/sign-in-page/sign-in-page.module').then((m) => m.SignInPageModule)
  ),

  { path: '**', redirectTo: '/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
