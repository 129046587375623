import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import {
  AuthorModel,
  BlogsListModel,
  ContactUsFormModel,
  CreateUserModel, JointUsFormModel, PostModel,
  RequestDemoFormModel,
  ResponseModel,
  SignUpModel
} from '../models/sign-up.model';
import { ComingSoonPageTypeEnum } from "../../../models/coming-soon-page.model";

@Injectable()

export class ApiService {
  private readonly apiGetStarted: string = '/get-started/send-request';
  private readonly apiFindUser: string = '/get-started/find-user';
  private readonly apiCreateUser: string = '/get-started/create-user';
  private readonly apiContactUs: string = '/contact-us/add';
  private readonly apiJointUs: string = '/contact-us/join-runple-team';
  private readonly apiRequestDemo: string = '/get-started/save-demo';

  constructor(
    private readonly http: HttpClient,
  ) { }

  public sendRequest(data: SignUpModel): Observable<any> {
    return this.http.post<Observable<any>>(this.apiGetStarted, {...data, type: 'RUNBILL'});
  }

  public findUser(token: string): Observable<any> {
    return this.http.get<Observable<any>>(`${this.apiFindUser}/${token}`);
  }

  public createUser(data: CreateUserModel, token: string): Observable<any> {
    return this.http.post<Observable<any>>(`${this.apiCreateUser}/${token}`, {...data});
  }

  public emailResend(token: string): Observable<any> {
    return this.http.get<ResponseModel<any>>( `/get-started/resend-email-verify/${token}`);
  }

  public contactUs(data: ContactUsFormModel): Observable<any> {
    return this.http.post<Observable<any>>(this.apiContactUs, {...data});
  }

  public jointUs(data: JointUsFormModel): Observable<any> {
    return this.http.post<Observable<any>>(this.apiJointUs, {...data});
  }

  public requestDemo(data: RequestDemoFormModel): Observable<any> {
    return this.http.post<Observable<any>>(this.apiRequestDemo, {...data});
  }

  public validateWorkspaceDomain(domain: string): Observable<any> {
    const params = new HttpParams().set('domain', domain);
    return this.http.get<ResponseModel<any>>('/hq/v1/workspace/validate', {params})
      .pipe(
        map((response: ResponseModel<any>) => {
          return response.data;
        })
      );
  }

  public getLegalTypes(countryCodeIso3 = 'AUT'): Observable<any> {
    const params = new HttpParams().set('countryCodeIso3', countryCodeIso3);
    return this.http.get<ResponseModel<any>>('/accounting/v1/utils/profile/legal-types', { params });
  }

  public getArticleList(): Observable<BlogsListModel> {
    return this.http.get<any>('/blog/list');
  }

  public getArticleListByCategory(id: number): Observable<BlogsListModel> {
    return this.http.get<any>(`/blog/list/category/${id}`);
  }

  public getPost(name: string): Observable<PostModel> {
    return this.http.get<any>(`/blog/post/${name}`);
  }

  public getLexiconList(): Observable<any> {
    return this.http.get<any>(`/lexicon/list`);
  }

  public getLexiconListByCategory(id: number): Observable<BlogsListModel> {
    return this.http.get<any>(`/lexicon/list/category/${id}`);
  }

  public getLexicon(name: string): Observable<PostModel> {
    return this.http.get<any>(`/lexicon/post/${name}`);
  }

  public getAuthorProfile(name: string): Observable<AuthorModel> {
    return this.http.get<any>(`/authors/authors-page/${name}`);
  }

  public emailSubscription(email: string, type: ComingSoonPageTypeEnum): Observable<any> {
    const params = {
      email: email,
      type: type
    };

    return this.http.post<ResponseModel<any>>('/get-started/email-subscription', params);
  }

  public tradeOfferConfirmation(token: string): Observable<any> {
    return this.http.post(`/api/v1/trade-offers/reply`,
      {
        secret: token
      }
    );
  }

  public allowedWorkspace(email: string): Observable<any> {
    return this.http.post(`/api/v1/auth/allowed-workspaces`,
      {
        email: email
      }
    );
  }
}
