export function getFeaturesList(
  tradeLink: string,
  ecommerceLink: string,
  servicesLink: string,
  retailLink: string,
  accountingLink: string,
  warehouseLink: string,
  analyticsLink: string,
  partnersLink: string,
  productsLink: string,
  teamLink: string,
): any[] {
 return [
   // {
   //   title: 'MAIN.MODULES',
   //   navList: [
   //     {
   //       icon: 'wholesale-icon.png',
   //       title: 'MAIN.INVENTORY_MANAGEMENT',
   //       routerLink: tradeLink
   //     },
   //     {
   //       icon: 'e-commerce-icon.png',
   //       title: 'MAIN.ECOMMERCE',
   //       routerLink: ecommerceLink
   //     },
   //     {
   //       icon: 'services-icon.png',
   //       title: 'MAIN.SERVICES',
   //       routerLink: servicesLink
   //     },
   //     {
   //       icon: 'retail-icon.png',
   //       title: 'MAIN.RETAIL',
   //       routerLink: retailLink
   //     },
   //   ]
   // },
   {
     title: 'MAIN.FEATURES',
     navList: [
       {
         icon: 'accounting-menu.svg',
         title: 'MAIN.ACCOUNTING',
         routerLink: accountingLink
       },
       {
         icon: 'sales-menu.svg',
         title: 'MAIN.SALES',
         routerLink: tradeLink
       },
       // {
       //   icon: 'warehouse-icon.png',
       //   title: 'MAIN.WAREHOUSE',
       //   routerLink: warehouseLink
       // },
       {
         icon: 'business-intelligence-menu.svg',
         title: 'MAIN.BUSINESS_INTELLIGENCE',
         routerLink: analyticsLink
       },
     ]
   },
   {
     // title: ' ',
     navList: [
       {
         icon: 'products-menu.svg',
         title: 'MAIN.PRODUCTS',
         routerLink: productsLink
       },
       {
         icon: 'partners-menu.svg',
         title: 'MAIN.PARTNERS',
         routerLink: partnersLink
       },

       {
         icon: 'teams-menu.svg',
         title: 'MAIN.TEAM',
         routerLink: teamLink
       },
     ]
   },
 ];
}

export function getSolutionsList(
  inventoryManagementLink: string,
  ecommerceSoftwareLink: string,
  pointOfSaleLink: string,
  accountingSoftWareLink: string,
): any[] {
  return  [
    {
      icon: 'accounting-icon.png',
      title: 'MAIN.ACCOUNTING_SOFT_WARE',
      routerLink: accountingSoftWareLink
    },
    {
      icon: 'wholesale-icon.png',
      title: 'MAIN.INVENTORY_MANAGEMENT_SOFTWARE',
      routerLink: inventoryManagementLink
    },
    {
      icon: 'e-commerce-icon.png',
      title: 'MAIN.ECOMMERCE_SOFTWARE',
      routerLink: ecommerceSoftwareLink
    },
    // {
    //   icon: 'services-icon.png',
    //   title: 'Software for service providers',
    //   routerLink: ''
    // },
    {
      icon: 'retail-icon.png',
      title: 'MAIN.POINT_OF_SALE',
      routerLink: pointOfSaleLink
    },
  ];
}

