<header id="header" class="header-container">
  <div class="header-wrapper">
    <div class="header-logo">
      <a [routerLink]="localeService.rootRoute" class="link md" aria-label="start page">
        <svg width="128" height="32" viewBox="0 0 128 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_7949_64588" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="32">
            <rect width="127.256" height="32" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_7949_64588)">
            <path d="M122.419 0H127.256V32H122.419V0Z" fill="black"/>
            <path d="M112.744 0H117.581V32H112.744V0Z" fill="black"/>
            <path d="M103.07 11.1628H107.907V32H103.07V11.1628Z" fill="black"/>
            <rect x="102.326" y="2.23256" width="6.32558" height="6.32558" rx="3.16279" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.7209 32C88.426 32 85.5964 30.0081 84.3705 27.1628H91.7209H91.907V27.1574C93.5671 27.0611 94.8837 25.6843 94.8837 24C94.8837 22.3157 93.5671 20.9389 91.907 20.8426V20.8372H91.7209H84.3705L83.7209 20.8372V16L91.7209 16H91.907V16.0021C96.2393 16.101 99.7209 19.6439 99.7209 24C99.7209 28.4183 96.1392 32 91.7209 32Z" fill="black"/>
            <path d="M74.0465 32H91.1628V27.1628H74.0465V32Z" fill="black"/>
            <path d="M74.0465 4.83741H84.4651V0.000198364H76.2791L74.0465 4.83741Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6661 16H84.4651C87.5476 16 90.0465 13.5011 90.0465 10.4186C90.0465 7.33605 87.5476 4.83717 84.4651 4.83717H75.6661C77.5144 1.92939 80.7644 -4.57764e-05 84.4651 -4.57764e-05C90.2192 -4.57764e-05 94.8837 4.66452 94.8837 10.4186C94.8837 16.1726 90.2192 20.8372 84.4651 20.8372V20.8372H74.0465V16L75.6661 16ZM74.0465 10.4186L74.0465 10.4313V10.4058L74.0465 10.4186Z" fill="black"/>
            <rect x="74.0465" y="0.000198364" width="4.83721" height="32" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M53.9535 21.5814L53.9535 32H49.1163V21.5814C49.1163 15.8274 53.7809 11.1628 59.5349 11.1628C65.2889 11.1628 69.9535 15.8274 69.9535 21.5814C69.9535 21.5835 69.9535 21.5856 69.9535 21.5878V32H65.1163L65.1163 30.3804V21.5814C65.1163 18.4989 62.6174 16 59.5349 16C56.4524 16 53.9535 18.4989 53.9535 21.5814Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.093 32C30.339 32 25.6744 27.3354 25.6744 21.5814C25.6744 21.5771 25.6744 21.5729 25.6744 21.5687L25.6744 11.1628H30.5116L30.5116 21.5814C30.5116 24.6639 33.0105 27.1628 36.093 27.1628C39.1755 27.1628 41.6744 24.6639 41.6744 21.5814V12.7824L41.6744 11.1628H46.5116V21.5814H46.5116C46.5116 27.3354 41.8471 32 36.093 32Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000183105 16L12.2793 16C15.3618 16 17.8607 13.5011 17.8607 10.4186C17.8607 7.33609 15.3618 4.83721 12.2793 4.83721H3.48021H0.000183105L2.23274 0H12.2792C18.0333 0 22.6978 4.66457 22.6978 10.4186C22.6978 15.0614 19.6609 18.995 15.4652 20.3411L25.2986 32L18.9769 32L9.55831 20.8372H0.000183105V16Z" fill="black"/>
            <path d="M4.83721 0V32H0V0H4.83721Z" fill="black"/>
          </g>
        </svg>
      </a>
<!--// for iphone-->
      <a [routerLink]="localeService.rootRoute" class="link sm" aria-label="start page" (click)="closeNav(solutionGroup, featuresGroup)">
        <svg width="128" height="32" viewBox="0 0 128 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_7949_64588" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="32">
            <rect width="127.256" height="32" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_7949_64588)">
            <path d="M122.419 0H127.256V32H122.419V0Z" fill="black"/>
            <path d="M112.744 0H117.581V32H112.744V0Z" fill="black"/>
            <path d="M103.07 11.1628H107.907V32H103.07V11.1628Z" fill="black"/>
            <rect x="102.326" y="2.23256" width="6.32558" height="6.32558" rx="3.16279" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.7209 32C88.426 32 85.5964 30.0081 84.3705 27.1628H91.7209H91.907V27.1574C93.5671 27.0611 94.8837 25.6843 94.8837 24C94.8837 22.3157 93.5671 20.9389 91.907 20.8426V20.8372H91.7209H84.3705L83.7209 20.8372V16L91.7209 16H91.907V16.0021C96.2393 16.101 99.7209 19.6439 99.7209 24C99.7209 28.4183 96.1392 32 91.7209 32Z" fill="black"/>
            <path d="M74.0465 32H91.1628V27.1628H74.0465V32Z" fill="black"/>
            <path d="M74.0465 4.83741H84.4651V0.000198364H76.2791L74.0465 4.83741Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6661 16H84.4651C87.5476 16 90.0465 13.5011 90.0465 10.4186C90.0465 7.33605 87.5476 4.83717 84.4651 4.83717H75.6661C77.5144 1.92939 80.7644 -4.57764e-05 84.4651 -4.57764e-05C90.2192 -4.57764e-05 94.8837 4.66452 94.8837 10.4186C94.8837 16.1726 90.2192 20.8372 84.4651 20.8372V20.8372H74.0465V16L75.6661 16ZM74.0465 10.4186L74.0465 10.4313V10.4058L74.0465 10.4186Z" fill="black"/>
            <rect x="74.0465" y="0.000198364" width="4.83721" height="32" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M53.9535 21.5814L53.9535 32H49.1163V21.5814C49.1163 15.8274 53.7809 11.1628 59.5349 11.1628C65.2889 11.1628 69.9535 15.8274 69.9535 21.5814C69.9535 21.5835 69.9535 21.5856 69.9535 21.5878V32H65.1163L65.1163 30.3804V21.5814C65.1163 18.4989 62.6174 16 59.5349 16C56.4524 16 53.9535 18.4989 53.9535 21.5814Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.093 32C30.339 32 25.6744 27.3354 25.6744 21.5814C25.6744 21.5771 25.6744 21.5729 25.6744 21.5687L25.6744 11.1628H30.5116L30.5116 21.5814C30.5116 24.6639 33.0105 27.1628 36.093 27.1628C39.1755 27.1628 41.6744 24.6639 41.6744 21.5814V12.7824L41.6744 11.1628H46.5116V21.5814H46.5116C46.5116 27.3354 41.8471 32 36.093 32Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000183105 16L12.2793 16C15.3618 16 17.8607 13.5011 17.8607 10.4186C17.8607 7.33609 15.3618 4.83721 12.2793 4.83721H3.48021H0.000183105L2.23274 0H12.2792C18.0333 0 22.6978 4.66457 22.6978 10.4186C22.6978 15.0614 19.6609 18.995 15.4652 20.3411L25.2986 32L18.9769 32L9.55831 20.8372H0.000183105V16Z" fill="black"/>
            <path d="M4.83721 0V32H0V0H4.83721Z" fill="black"/>
          </g>
        </svg>
      </a>
    </div>

    <ul class="header-navigation">
<!--      <li class="header-navigation-item pr-0">-->
<!--        {{'MAIN.SOLUTIONS' | translate}}-->
<!--        <span class="icon">-->
<!--          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="white"/>-->
<!--          </svg>-->
<!--        </span>-->

<!--        <div class="sub-menu-container solutions">-->

<!--          <div class="sub-menu-wrapper p-16">-->
<!--            <div class="navigate-group pt-0">-->
<!--              <ul class="nav-list">-->
<!--                <li *ngFor="let item of solutionsList; trackBy: trackByFn" class="nav-item">-->
<!--                  <a [routerLink]="item?.routerLink" class="link">-->
<!--                    <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">-->
<!--                    <span class="title">{{item.title | translate}}</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </li>-->
      <li class="header-navigation-item pr-8">
        {{'MAIN.FEATURES' | translate}}
        <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="black"/>
          </svg>
        </span>

        <div class="sub-menu-container">

          <div class="sub-menu-wrapper">
            <div *ngFor="let group of featuresList; trackBy: trackByFn" class="navigate-group">
                <span *ngIf="!!group?.title" class="navigate-group-title font-700">{{group.title | translate}}</span>
                <ul class="nav-list" [class.mt-40]="!group?.title">
                  <li *ngFor="let item of group.navList; trackBy: trackByFn" class="nav-item">
                    <a [routerLink]="item?.routerLink" class="link">
                      <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" [alt]="item?.title">
                      <span class="title">{{item.title | translate}}</span>
                    </a>
                  </li>
                </ul>
              </div>
          </div>

        </div>
      </li>
      <li class="header-navigation-item">
        <a [routerLink]="localeService.getLink(routesEnum.PRICING)" class="link">{{'MAIN.PRICING' | translate}}</a>
      </li>
<!--      <li class="header-navigation-item">-->
<!--        <a [routerLink]="'about'" class="link">About Runple</a>-->
<!--      </li>-->
    </ul>

    <div class="header-actions">
      <button type="button" class="btn btn-md btn-transparent-black mobile-none" aria-label="Log in" (click)="goToSignIn()">
        <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="black"/>
          <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="black"/>
        </svg>
        {{'MAIN.SIGN_IN' | translate}}
      </button>

      <button *ngIf="!(hideStartToFreeAction$ | async)" type="button" class="btn btn-md" aria-label="Start for free" (click)="goToSignUpPage(); closeNav(solutionGroup, featuresGroup)">
        <img class="desktop" height="24" width="24" ngSrc="assets/img/cursor.svg" alt="">
        <img class="tablet" height="24" width="24" ngSrc="assets/img/finger-tap.svg" alt="">
        {{'MAIN.START_FOR_FREE' | translate}}
      </button>

      <button type="button" class="btn btn-md btn-icon btn-black menu-toggle" aria-label="Navigation toggle"
              (click)="navToggle(); solutionGroup.setAttribute('open', ''); featuresGroup.removeAttribute('open')"
      >
        <img *ngIf="!isOpenNav" height="24" width="24" ngSrc="assets/img/menu.svg" alt="">
        <img *ngIf="isOpenNav" height="24" width="24" ngSrc="assets/img/close.svg" alt="">
      </button>
    </div>
  </div>

  <div class="mobile-navigation" [class.open]="isOpenNav">
    <div class="mobile-navigation-container">
      <div #solutionGroup class="mobile-navigation-group" open>
<!--        <div class="title-container d-flex align-items-center" (click)="solutionGroup.toggleAttribute('open')">-->
<!--          <span class="title font-700">{{'MAIN.SOLUTIONS' | translate}}</span>-->
<!--          <span class="icon">-->
<!--          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path class="toggle" fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="black"/>-->
<!--          </svg>-->
<!--        </span>-->
<!--        </div>-->

<!--        <div class="navigate-group-container">-->
<!--          <div class="navigate-group pt-0">-->
<!--            <ul class="nav-list">-->
<!--              <li *ngFor="let item of solutionsList; trackBy: trackByFn" class="nav-item">-->
<!--                <a [routerLink]="item?.routerLink" (click)="closeNav(solutionGroup, featuresGroup)" class="link">-->
<!--                  <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">-->
<!--                  <span class="title">{{item.title | translate}}</span>-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div #featuresGroup class="mobile-navigation-group">
        <div class="title-container d-flex align-items-center" (click)="featuresGroup.toggleAttribute('open')">
          <span class="title font-700">{{'MAIN.FEATURES' | translate}}</span>
          <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="black"/>
          </svg>
        </span>
        </div>

        <div class="navigate-group-container">
          <div *ngFor="let group of featuresList; trackBy: trackByFn" class="navigate-group">
<!--            <span *ngIf="!!group?.title" class="navigate-group-title font-700">{{group.title | translate}}</span>-->
            <ul class="nav-list">
              <li *ngFor="let item of group.navList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="item?.routerLink" (click)="closeNav(solutionGroup, featuresGroup)" class="link">
                  <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">
                  <span class="title">{{item.title | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mobile-navigation-group">
        <a [routerLink]="localeService.getLink(routesEnum.PRICING)" (click)="closeNav(solutionGroup, featuresGroup)" class="link font-700">{{'MAIN.PRICING' | translate}}</a>
      </div>

<!--      <div class="mobile-navigation-group">-->
<!--        <a [routerLink]="'about'" (click)="closeNav(solutionGroup, featuresGroup)" class="link font-700">About Runple</a>-->
<!--      </div>-->

      <div class="action">
        <button type="button" class="btn btn-md btn-black" aria-label="Log in" (click)="goToSignIn(); closeNav(solutionGroup, featuresGroup)">
          <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="white"/>
            <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="white"/>
          </svg>
          {{'MAIN.SIGN_IN' | translate}}
        </button>
      </div>
    </div>

  </div>
</header>

<div class="header-overlay"
     [class.show]="isOpenNav"
     (click)="navToggle(); solutionGroup.removeAttribute('open'); featuresGroup.removeAttribute('open')"
></div>
